/* p.sliderimg {
    width: 80%;
    align-self: center;
    object-fit: cover
    } */
.contact {
        margin: auto;
}
.slideInner___2mfX9 {
    text-align: center;
}
h3 {
    text-align: center;
}


.header {
    overflow: hidden;
    background-color: #f1f1f1;
    /* padding: 20px 10px; */
    padding: 10px;
    text-align: center;
  }

.carousel {
    text-align: center;
}

.a {
    vertical-align: center;
}

.bio {
    text-align: center;
    font-size: 16px;
}

.header a {
    /* float: m; */
    color: black;
    text-align: center;
    padding: 12px;
    text-decoration: none;
    font-size: 18px;
    line-height: 25px;
    border-radius: 4px;
}

.header a:hover {
    background-color: #ddd;
    color: black;
}

span:hover {
    cursor: pointer
}

.title {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    font-family: "Caveat Brush";
    letter-spacing: 3px;
    word-spacing: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.button {
    margin-top: 20px;
    margin-inline: 10px;
}